import { Injectable } from '@angular/core';
import { Alert, Alert2 } from '../_model/ebook';
import { Subject, Observable } from 'rxjs';


@Injectable()
export class AlertService{
  private subject = new Subject<Alert>();
  private subject2 = new Subject<Alert2>();

  sendData(alert: Alert) {
    this.subject.next(alert);
  }

  clearData() {
    this.subject = new Subject<Alert>();
  }

  getData(): Observable<Alert> {
    return this.subject.asObservable();
  }


  sendData2(alert2: Alert2) {
    this.subject2.next(alert2);
  }

  clearData2() {
    this.subject2 = new Subject<Alert2>();
  }

  getData2(): Observable<Alert2> {
    return this.subject2.asObservable();
  }
}

