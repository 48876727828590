import { Directive, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})

export class NumberDirective {
  constructor(private el: ElementRef) { }

  @Input() OnlyNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.OnlyNumber) {
      if( (e.keyCode > 47 && e.keyCode < 58) || e.keyCode === 8 || e.keyCode === 9 ){

      } else {
        e.preventDefault();
      }
    }
  }
}
