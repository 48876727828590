import { HttpProvider } from "./providers/http.provider";
import { Component, ViewChild, ElementRef, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { SharedService } from "./providers/shared.service";
import { AlertService } from "./providers/alert.service";
import * as $ from "jquery";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  @ViewChild("video", { static: false }) src: ElementRef;
  public content = "";
  public alertPassword = "";
  public view = true;
  public guide = false;
  public alert = false;
  public inputAlert = false;
  public activeBtn = false;
  public activeBtn2 = false;
  public popup = false;
  public onMenu = false;
  public focus = false;
  public imgView = false;
  routerUrl: string;
  menu = 0;
  ebooks: any = [
    { id: 1, grade: "1" },
    { id: 2, grade: "2" },
    { id: 3, grade: "3-1" },
    { id: 4, grade: "3-2" },
    { id: 5, grade: "4-1" },
    { id: 6, grade: "4-2" },
    { id: 7, grade: "5-1" },
    { id: 8, grade: "5-2" },
    { id: 9, grade: "6-1" },
    { id: 10, grade: "6-2" },
  ];

  // 노래
  busu: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];

  logic: any = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];

  // 중학교 한문 한자부수
  middleBusu: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];

  timeGuide: any;
  dialog: boolean;
  alertMsg: string;
  loadAPI: Promise<any>;

  constructor(
    private http: HttpProvider,
    private shared: SharedService,
    private alertService: AlertService,
    private router: Router
  ) {
    this.brawer();

    $.getJSON("https://httpbin.org/ip", (data) => {
      this.http.post("save", { data: data["origin"] }).then((res) => {});
    });
  }

  brawer() {
    setInterval(() => {
      this.menu++;
      this.menu = this.menu % 3;
    }, 2000);
  }

  public changeView(event: boolean) {
    window.scrollTo(0, 0);
    this.view = event;
    this.routerUrl = this.router.url.substring(1).split(";")[0];

    if (this.routerUrl !== "ebook1") {
      this.activeBtn = false;
    }

    if (this.routerUrl !== "ebook2") {
      this.activeBtn2 = false;
    }
  }

  loadOn() {
    this.imgView = true;
  }

  radial() {
    if (this.routerUrl === "ebook1" && !this.activeBtn) {
      this.activeBtn = true;
    } else if (this.routerUrl === "ebook1" && this.activeBtn) {
      this.activeBtn = false;
    } else if (this.routerUrl === "ebook2" && !this.activeBtn2) {
      this.activeBtn2 = true;
    } else if (this.routerUrl === "ebook2" && this.activeBtn2) {
      this.activeBtn2 = false;
    }
  }

  info(idx: number) {
    if (idx === 0) {
      this.src.nativeElement.setAttribute(
        "src",
        environment.url + "media/movies/intro1.mp4"
      );
    } else if (idx === 1) {
      this.src.nativeElement.setAttribute(
        "src",
        environment.url + "media/movies/intro2.mp4"
      );
    }
    this.src.nativeElement.setAttribute("autoplay", "true");
    this.popup = true;
    this.onGuide();
  }

  onGuide() {
    this.guide = true;
    clearTimeout(this.timeGuide);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
      navigator.userAgent
    )
      ? true
      : false;
    if (isMobile) {
      this.timeGuide = setTimeout(() => {
        this.guide = false;
      }, 5000);
    }
  }

  offPopup() {
    this.popup = false;
    this.src.nativeElement.setAttribute("src", "");
    this.src.nativeElement.setAttribute("autoplay", "false");
  }

  ebook(id: number) {
    const src = environment.url + "ebook/try" + "/e" + id;
    window.open(src);
  }

  dongyi(type, index) {
    this.router.navigate(["ebook1"]);
    setTimeout(() => {
      const data = { type: 0, index: 0 };
      data.type = type;
      data.index = index;
      this.shared.sendData(data);
    }, 100);
  }

  middleDongyi(type, index) {
    this.router.navigate(["ebook3"]);
    setTimeout(() => {
      const data = { type: 0, index: 0 };
      data.type = type;
      data.index = index;
      this.shared.sendData(data);
    }, 100);
  }

  goLogic(type, index) {
    this.router.navigate(["ebook2"]);
    setTimeout(() => {
      const data = { type: 0, index: 0 };
      data.type = type;
      data.index = index;
      this.shared.sendData(data);
    }, 100);
  }

  onMedia(type, id) {
    if (type === "learn") {
      this.src.nativeElement.setAttribute(
        "src",
        environment.url + "media/songs/learn/song_e" + id + ".mp4"
      );
    } else if (type === "busu") {
      this.src.nativeElement.setAttribute(
        "src",
        environment.url + "media/songs/busu/busu_song" + id + ".mp4"
      );
    } else if (type === "intro") {
      this.src.nativeElement.setAttribute(
        "src",
        environment.url + "media/movies/intro" + id + ".mp4"
      );
    }

    this.src.nativeElement.setAttribute("autoplay", "true");
    this.popup = true;
    this.onGuide();
  }

  async existFile(url: string) {
    try {
      const response = await fetch(url);

      if (response.ok) {
        const data = await response.json();
        if (data.valid) {
          return true;
        } else {
          alert("준비중입니다.");
          return false;
        }
      } else {
        alert("준비중입니다.");
        return false;
      }
    } catch (error) {
      alert("준비중입니다.");
      return false;
    }
  }

  async onMiddleMedia(type: string, id: number) {
    const src = `${environment.url}mid/${type}/song${id}.mp4`;
    const checkSrc = `${environment.url}api/check-url?path=mid/${type}/song${id}.mp4`;
    const result = await this.existFile(checkSrc);
    if (result) {
      this.src.nativeElement.setAttribute("src", src);
      this.src.nativeElement.setAttribute("autoplay", "true");
      this.popup = true;
      this.onGuide();
    }
  }

  @HostListener("document:click", ["$event"]) onDocumentClick(event) {
    const className = event.srcElement.className;
    const flag = className.indexOf("menu");
    if (flag === -1) {
      this.onMenu = false;
    }
  }

  @HostListener("wheel", ["$event"]) handleWheelEvent(event) {
    if (this.popup || this.alert || this.dialog) {
      event.preventDefault();
    }
  }

  @HostListener("window:keydown", ["$event"]) handleKeyDown(
    event: KeyboardEvent
  ) {
    if (!this.focus) {
      switch (event.key + "") {
        case "Escape": {
          this.activeBtn = false;
          this.activeBtn2 = false;
          this.offPopup();
          break;
        }
      }
    }
  }

  onSwipeLeft(event) {
    this.router.navigate(["ebook1"]);
  }

  onSwipeRight(event) {
    this.router.navigate(["ebook2"]);
  }

  onDialog(msg: string) {
    this.content = msg;
    this.dialog = true;
  }

  offDialog() {
    this.content = "";
    this.dialog = false;
  }

  onAlert(msg: string) {
    this.alertMsg = msg;
    this.alert = true;
  }

  offAlert() {
    this.alertMsg = "";
    this.alert = false;
  }

  onAlert2() {
    this.inputAlert = true;
  }

  offAlert2() {
    this.inputAlert = false;
    this.resetPwd();
  }

  alt(value) {
    this.alertService.sendData(value);
  }

  alt2(result, value) {
    const data = {
      result,
      password: value,
    };
    this.alertService.sendData2(data);
  }

  resetPwd() {
    this.alertPassword = "";
  }
}
