import { Directive, HostListener } from '@angular/core';
import { AppComponent } from '../app.component';

@Directive({
  selector: '[focusInput]'
})

export class FocusDirective {
  constructor(public app: AppComponent) { }
  @HostListener('focus', ['$event.target']) onFocus(target) {
    this.app.focus = true;
  }

  @HostListener('focusout', ['$event.target']) onFocusout(target) {
    this.app.focus = false;
  }
}
