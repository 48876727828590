import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";

@Injectable()
export class HttpProvider {
  DB_API: string = environment.url + "api/";

  constructor(private http: HttpClient) {}

  get(SQL: string) {
    return new Promise((resolve) => {
      this.http.get(this.DB_API + SQL).subscribe((res) => {
        resolve(res);
      });
    });
  }

  get2(SQL: string) {
    return new Promise((resolve) => {
      this.http.get(SQL).subscribe((res) => {
        resolve(res);
      });
    });
  }

  post(SQL: string, arr: any) {
    return new Promise((resolve) => {
      this.http.post(this.DB_API + SQL, arr).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
