import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment.prod";
const url = environment.url + "api/upload";

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  public upload(files: Set<File>): {
    [key: string]: { progress: Observable<number> };
  } {
    const status: {
      [key: string]: { progress: Observable<number>; name: string };
    } = {};

    files.forEach((file) => {
      const randomName = this.newName(file.name);
      const formData: FormData = new FormData();
      formData.append("file", file, randomName);

      const req = new HttpRequest("POST", url, formData, {
        reportProgress: true,
      });

      const progress = new Subject<number>();
      this.http.request(req).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {
          progress.complete();
        }
      });

      status[file.name] = {
        progress: progress.asObservable(),
        name: randomName,
      };
    });
    return status;
  }

  newName(filename) {
    const nameArr = filename.split(".");
    const idx = nameArr.length - 1;
    const extension = nameArr[idx];
    // const result = this.newName() + '.' + extension;
    const chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
    const sLength = 20;
    let randomstring = "";
    for (let i = 0; i < sLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring + "." + extension;
  }
}
