import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from '../app.component';
import { HttpProvider } from '../providers/http.provider';
import { forkJoin } from 'rxjs';
import { UploadService } from '../upload/upload.service';
import { AlertService } from '../providers/alert.service';
import { Router } from '@angular/router';
import { SharedService } from '../providers/shared.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  @ViewChild('main', {static: false}) cards: ElementRef;
  @ViewChild('file', {static: false}) file;
  @ViewChild('file2', {static: false}) file2;
  public files: Set<File> = new Set();
  password = '';
  onPwd = true;
  type = true;
  insertForm = false;
  updateForm = false;
  gs = false;
  filteredItems: any;
  partner = [];
  notice = [];
  selected = {
    id: 0,
    content: ''
  };
  insertCompany = {
    id: 0,
    name: '',
    pwd: '',
    pwd2: '',
    tel: '',
    addr: '',
    site: '',
    file: '',
    filename: '',
    memo: '',
    item1: '',
    item2: '',
    item3: '',
    item4: '',
    item5: ''
  };
  updateCompany = {
    id: 0,
    index: 0,
    name: '',
    pwd: '',
    pwd2: '',
    tel: '',
    addr: '',
    site: '',
    file: '',
    filename: '',
    memo: '',
    item1: '',
    item2: '',
    item3: '',
    item4: '',
    item5: ''
  };
  index: any;
  progress: any;
  fileLth: File;

  constructor(
    public app: AppComponent,
    public uploadService: UploadService,
    public alertService: AlertService,
    public sharedService: SharedService,
    public router: Router,
    public http: HttpProvider
  ) { }

  ngOnInit() {
    this.app.changeView(false);
    this.getPartner();
    this.getNotice();
  }

  disable() {
    this.selected = {
      id: 0,
      content: ''
    };
  }

  view(list) {
    if (this.selected.id === list.id) {
      this.disable();
    } else {
      this.selected.id = list.id;
      this.selected.content = list.content;
    }
  }

  checkPwd() {
    if ( this.type ) { // 글샘
      if (this.password === '5425') {
        this.onPwd = false;
        this.gs = true;
      } else {
        alert('비밀번호가 일치하지 않습니다');
      }
    } else {
      if (this.password === '8989') {
        this.onPwd = false;
        this.gs = false;
      } else {
        alert('비밀번호가 일치하지 않습니다');
      }
    }
  }

  getNotice() {
    this.http.get('select_p_notice').then(res => {
      if (res['result']) {
        this.notice = res['data'];
      } else {
        alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
      }
    });
  }

  getPartner() {
    this.http.get('select_partner').then(res => {
      if (res['result']) {
        this.partner = res['data'];
        this.assignCopy();
      } else {
        alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
      }
    });
  }

  registerBtn() {
    this.insertForm = !this.insertForm;
    if (this.insertForm) {
      this.updateForm = false;
    }
  }

  register() {
    if (this.insertCompany.pwd !== '' && (this.insertCompany.pwd === this.insertCompany.pwd2)) {
      if (this.insertCompany.name === '' || this.insertCompany.tel === '' || this.insertCompany.addr === '' || this.insertCompany.name === '') {
        alert('입력되지 않은 항목이 있습니다');
      } else {
        this.app.onDialog('작성중..');
        const files = this.file.nativeElement.files;
        const length = files.length;
        this.insertCompany.file = length > 0 ? files[0].name : '';

        if (length > 0) {
          this.fileUpload().then( res => {
            this.insertCompany.filename = res['name'];
            this.insertQuery();
          });
        } else {
          this.insertQuery();
        }
      }
    } else {
      alert('비밀번호가 일치하지 않습니다.');
      this.insertCompany.pwd = '';
      this.insertCompany.pwd2 = '';
    }
  }

  insertQuery() {
    this.http.post('insert_partner', this.insertCompany).then(res => {
      if (res['result']) {
        this.insertForm = false;
        this.insertCompany.id = res['id'];
        this.partner.push(this.insertCompany);
        this.assignCopy();
        this.resetInsert();
        this.fileUpload();
        this.app.offDialog();
      } else {
        this.app.offDialog();
        alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
      }
    });
  }

  deletePartner(id, idx) {
    if (this.gs) {
      this.app.onAlert('삭제하시겠습니까?');
      this.alertService.clearData();
      this.alertService.getData().subscribe(result => {
        if (result) {
          this.http.get('delete_partner?id=' + id).then(res => {
            if (res['result']) {
              this.partner.splice(idx, 1);
              this.assignCopy();
            } else {
              alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
            }
            this.app.offAlert();
          });
        } else {
          this.app.offAlert();
        }
      });
    } else {
      this.app.onAlert2();
      this.alertService.clearData2();
      this.alertService.getData2().subscribe(result => {
        if (result.result) {
          if ( result.password !== this.partner[idx].pwd) {
            alert('비밀번호가 일치하지 않습니다');
            this.app.resetPwd();
          } else {
            this.app.onDialog('로딩중..');
            this.http.get('delete_partner?id=' + id).then(res => {
              if (res['result']) {
                this.partner.splice(idx, 1);
                this.assignCopy();
                this.app.offDialog();
              } else {
                alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
              }
              this.app.offAlert2();
            });
          }
        } else {
          this.app.offAlert2();
        }
      });
    }
  }

  modify() {
    if (!this.gs && (this.updateCompany.pwd !== this.updateCompany.pwd2)) {
      alert('잘못된 비밀번호입니다');
      this.updateCompany.pwd = '';
    } else {
      this.app.onAlert('수정하시겠습니까?');
      this.alertService.getData().subscribe(result => {
        this.alertService.clearData();
        if (result) {
          this.app.onDialog('작성중..');
          const files = this.file2.nativeElement.files;
          const length = files.length;
          this.updateCompany.file = length > 0 ? files[0].name : '';

          if (length > 0) {
            this.fileUpload().then( res => {
              this.updateCompany.filename = res['name'];
              this.updateQuery();
            });
          } else {
            this.updateQuery();
          }
        } else {
          this.app.offAlert();
        }
      });
    }
  }

  updateQuery() {
    this.http.post('update_partner', this.updateCompany).then(res => {
      if (res['result']) {
        const i = this.updateCompany.index;
        this.partner[i].name = this.updateCompany.name;
        this.partner[i].tel = this.updateCompany.tel;
        this.partner[i].addr = this.updateCompany.addr;
        this.partner[i].site = this.updateCompany.site;
        this.partner[i].memo = this.updateCompany.memo;
        this.partner[i].file = this.updateCompany.file === '' ? this.partner[i].file : this.updateCompany.file;
        this.partner[i].filename = this.updateCompany.filename === '' ? this.partner[i].filename : this.updateCompany.filename;
        this.partner[i].item1 = this.updateCompany.item1;
        this.partner[i].item2 = this.updateCompany.item2;
        this.partner[i].item3 = this.updateCompany.item3;
        this.partner[i].item4 = this.updateCompany.item4;
        this.partner[i].item5 = this.updateCompany.item5;
        this.partner[i].pwd = this.updateCompany.pwd2;
        this.resetUpdate();
        this.updateForm = false;
        this.assignCopy();
        this.app.offDialog();
      } else {
        alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
      }
      this.app.offAlert();
    });
  }

  cancelModify() {
    this.resetUpdate();
    this.updateForm = false;
  }

  updatePartner(id, idx) {
    this.updateForm = true;
    this.insertForm = false;
    const top: number = this.cards.nativeElement.offsetTop;
    window.scrollTo({left: 0, top, behavior: 'smooth'});
    this.updateCompany.id = this.partner[idx].id;
    this.updateCompany.index = idx;
    this.updateCompany.name = this.partner[idx].name;
    this.updateCompany.tel = this.partner[idx].tel;
    this.updateCompany.addr = this.partner[idx].addr;
    this.updateCompany.site = this.partner[idx].site;
    this.updateCompany.memo = this.partner[idx].memo;
    this.updateCompany.item1 = this.partner[idx].item1;
    this.updateCompany.item2 = this.partner[idx].item2;
    this.updateCompany.item3 = this.partner[idx].item3;
    this.updateCompany.item4 = this.partner[idx].item4;
    this.updateCompany.item5 = this.partner[idx].item5;
    this.updateCompany.pwd2 = this.partner[idx].pwd;
  }

  topRanker(id, idx, name) {
    this.app.onAlert(name + '을(를) 상단으로 올리시겠습니까?');
    this.alertService.getData().subscribe(result => {
      this.alertService.clearData();
      if (result) {
        this.http.get('top_partner?id=' + id).then(res => {
          if (res['result']) {
            const topCompany = this.partner[idx];
            this.partner.splice(idx, 1);
            this.partner.unshift(topCompany);
            this.assignCopy();
            const top: number = this.cards.nativeElement.offsetTop;
            window.scrollTo({left: 0, top, behavior: 'smooth'});
          } else {
            alert('네크워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요');
          }
          this.app.offAlert();
        });
      } else {
        this.app.offAlert();
      }
    });
  }

  resetInsert() {
    this.insertCompany = {
      id: 0,
      name: '',
      pwd: '',
      pwd2: '',
      tel: '',
      addr: '',
      site: '',
      file: '',
      filename: '',
      memo: '',
      item1: '',
      item2: '',
      item3: '',
      item4: '',
      item5: ''
    };
  }

  resetUpdate() {
    this.updateCompany = {
      id: 0,
      index: 0,
      name: '',
      pwd: '',
      pwd2: '',
      tel: '',
      addr: '',
      site: '',
      file: '',
      filename: '',
      memo: '',
      item1: '',
      item2: '',
      item3: '',
      item4: '',
      item5: ''
    };
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded(type) {
    const files: { [key: string]: File } = type ? this.file.nativeElement.files : this.file2.nativeElement.files;
    this.fileLth = files.length;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
  }

  fileUpload() {
    return new Promise(resolve => {
      const names = [];
      this.progress = this.uploadService.upload(this.files);
      const allProgressObservables = [];
      // tslint:disable-next-line:forin
      for (const key in this.progress) {
        allProgressObservables.push(this.progress[key].progress);
        names.push(this.progress[key].name);
      }

      forkJoin(allProgressObservables).subscribe(end => {
        this.app.offDialog();
        resolve({name:names[0]});
      });
    });
  }

  uploadFileLists(board_id) {
    const list = [];
    const files = this.file.nativeElement.files;
    for (let i = 0; i< files.length; i++) {
      const file = {
        name: files[i].name,
        size: files[i].size,
        type: files[i].type
      };
      list.push(file);
    }

    const data = {
      board_id,
      board_type: 0,
      files: list
    };
  }

  partnerNotice(value) {
    this.router.navigate(['notice']);
    this.app.onDialog('로딩중');
    setTimeout(() => {
      this.sharedService.sendNotice(value);
    }, 500);

  }

  assignCopy() {
    this.filteredItems = Object.assign([], this.partner);
  }

  filterItem(value) {
    if (!value) this.assignCopy(); // when nothing has typed
    this.filteredItems = Object.assign([], this.partner).filter(
      item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 );
  }

  link(url){
    url = url.indexOf('http') === -1 ? 'http://' + url : url;
    window.open(url);
  }
}
