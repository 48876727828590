import { NoticeComponent } from "./notice/notice.component";
import { MainComponent } from "./main/main.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Ebook1Component } from "./ebook1/ebook1.component";
import { Ebook2Component } from "./ebook2/ebook2.component";
import { IntroComponent } from "./intro/intro.component";
import { DownComponent } from "./down/down.component";
import { PartnerComponent } from "./partner/partner.component";
import { Ebook3Component } from "./ebook3/ebook3.component";

const routes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" }, //첫화면
  { path: "main", component: MainComponent },
  { path: "ebook1", component: Ebook1Component },
  { path: "ebook2", component: Ebook2Component },
  { path: "ebook3", component: Ebook3Component },
  { path: "intro", component: IntroComponent },
  { path: "down", component: DownComponent },
  { path: "notice", component: NoticeComponent },
  { path: "partner", component: PartnerComponent },
  { path: "**", redirectTo: "main", pathMatch: "full" }, // 오류시
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
