import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AppComponent } from "../app.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../providers/shared.service";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-ebook3",
  templateUrl: "./ebook3.component.html",
  styleUrls: ["./ebook3.component.scss"],
})
export class Ebook3Component implements OnInit {
  @ViewChild("hanjas", { static: false }) hanjas: ElementRef;
  @ViewChild("busu", { static: false }) busu: ElementRef;
  @ViewChild("ebooks", { static: false }) ebooks: ElementRef;
  @ViewChild("songs", { static: false }) songs: ElementRef;
  @ViewChild("ani", { static: false }) ani: ElementRef;
  @ViewChild("word", { static: false }) word: ElementRef;
  @ViewChild("poster", { static: false }) poster: ElementRef;
  @ViewChild("card", { static: false }) card: ElementRef;
  @ViewChild("mini", { static: false }) mini: ElementRef;
  @ViewChild("pan", { static: false }) pan: ElementRef;
  @ViewChild("same", { static: false }) same: ElementRef;
  @ViewChild("act", { static: false }) act: ElementRef;
  @ViewChild("test", { static: false }) test: ElementRef;
  type: number;
  index: number;
  song1: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];
  song2: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];

  hanmunSong: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
    { id: 6, grade: 6 },
    { id: 7, grade: 7 },
    { id: 8, grade: 8 },
    { id: 9, grade: 9 },
    { id: 10, grade: 10 },
  ];

  ebook9: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
    { id: 6, grade: 6 },
    { id: 7, grade: 7 },
    { id: 8, grade: 8 },
    { id: 9, grade: 9 },
  ];

  ebookBird: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
    { id: 6, grade: 6 },
  ];

  constructor(
    public app: AppComponent,
    public shared: SharedService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.shared.getData().subscribe((data) => {
      if (data.type) {
        this.type = data.type;
        this.index = data.index - 1;
        this.onScroll();
      }
    });

    this.app.changeView(false);
  }

  onScroll() {
    setTimeout(() => {
      let top: number;

      console.log(this.type);

      if (this.type === 1) {
        top = this.ebooks.nativeElement.offsetTop - 350;
      }
      if (this.type === 2) {
        top = this.songs.nativeElement.offsetTop - 350;
      }
      if (this.type === 3) {
        top = this.ani.nativeElement.offsetTop - 350;
      }
      if (this.type === 6) {
        top = this.word.nativeElement.offsetTop - 350;
      }
      if (this.type === 7) {
        top = this.poster.nativeElement.offsetTop - 350;
      }
      if (this.type === 8) {
        top = this.card.nativeElement.offsetTop - 350;
      }
      if (this.type === 9) {
        top = this.mini.nativeElement.offsetTop - 350;
      }
      if (this.type === 10) {
        top = this.pan.nativeElement.offsetTop - 350;
      }
      if (this.type === 11) {
        top = this.same.nativeElement.offsetTop - 350;
      }
      if (this.type === 12) {
        top = this.act.nativeElement.offsetTop - 350;
      }
      if (this.type === 13) {
        top = this.test.nativeElement.offsetTop - 350;
      }

      if (this.type === 4 || this.type === 5) {
        // 부수관련
        top = this.busu.nativeElement.offsetTop - 350;
      }
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    }, 100);
  }

  song(type: string, id: number) {
    this.app.onMiddleMedia(type, id);
  }

  async ebook(type: string, id: number) {
    const src = `${environment.url}mid/${type}/ebook${id}`;
    const checkUrl = `${environment.url}api/check-url?path=mid/${type}/ebook${id}/index.html`;
    console.log(src, checkUrl);
    const result = await this.existFile(checkUrl);
    if (result) {
      window.open(src);
    }
  }

  async pdf(type: string, id: number) {
    const src = `${environment.url}mid/${type}/${type}${id}.pdf`;
    const checkUrl = `${environment.url}api/check-url?path=mid/${type}/${type}${id}.pdf`;
    console.log(src, checkUrl);
    const result = await this.existFile(checkUrl);
    if (result) {
      window.open(src);
    }
  }

  async existFile(url: string) {
    try {
      const response = await fetch(url);

      if (response.ok) {
        const data = await response.json();
        if (data.valid) {
          return true;
        } else {
          alert("준비중입니다.");
          return false;
        }
      } else {
        alert("준비중입니다.");
        return false;
      }
    } catch (error) {
      alert("준비중입니다.");
      return false;
    }
  }

  async downloadZip(type: string, id: number) {
    if (id === 2) {
      const password = prompt("비밀번호를 입력하세요");
      if (password !== "5491155") {
        alert("유효하지 않은 비밀번호 입니다");
        return;
      }
    }
    const url = `${environment.url}mid/${type}/${type}${id}.zip`;
    const checkUrl = `${environment.url}api/check-url?path=mid/${type}/${type}${id}.zip`;
    const result = await this.existFile(checkUrl);
    if (result) {
      this.downloadFile(url, "sample.zip");
    }
  }

  async downloadJpg(type: string, id: number) {
    const url = `${environment.url}mid/${type}/${type}${id}.jpg`;
    const checkUrl = `${environment.url}api/check-url?path=mid/${type}/${type}${id}.jpg`;
    const result = await this.existFile(checkUrl);
    if (result) {
      this.downloadFile(url, "sample.jpg");
    }
  }

  private downloadFile(url: string, filename: string) {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  onSwipeLeft(event) {
    this.router.navigate(["ebook1"]);
  }
  onSwipeRight(event) {
    this.router.navigate(["ebook3"]);
  }
}
