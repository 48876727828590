import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(tel, args) {
    if (tel.length === 11) { // 010, 070
      return tel[0] + tel[1] + tel[2] +  '-'  + tel[3] + tel[4] + tel[5] + tel[6] + '-' + tel[7] + tel[8] + tel[9] + tel[10];
    } else if (tel.length === 10) {
      if (tel[1] === '2') { // 02, Seouel
        return tel[0] + tel[1] + '-' + tel[2] + tel[3] + tel[4] + tel[5] + '-' + tel[6] + tel[7] + tel[8] + tel[9];
      } else { // 011, 016......, 031, 041, 051 .........
        return tel[0] + tel[1] + tel[2] + '-' + tel[3] + tel[4] + tel[5] + '-' + tel[6] + tel[7] + tel[8] + tel[9];
      }
    } else if (tel.length === 9) {
      return tel[0] + tel[1] + '-' + tel[2] + tel[3] + tel[4] + '-' + tel[5] + tel[6] + tel[7] + tel[8];
    } else {
      return tel;
    }
  }
}
