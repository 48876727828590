import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { Ebook1Component } from './ebook1/ebook1.component';
import { Ebook2Component } from './ebook2/ebook2.component';
import { IntroComponent } from './intro/intro.component';
import { NoticeComponent } from './notice/notice.component';
import { PartnerComponent } from './partner/partner.component';
import { DownComponent } from './down/down.component';
import { ReversePipe } from './pipe/reserve';
import { PagerService } from './providers/pager.provider';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HttpProvider } from './providers/http.provider';
import { FocusDirective } from './directives/focus.directive';
import { APP_BASE_HREF, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedService } from './providers/shared.service';
import { UploadModule } from './upload/upload.module';
import { BytePipe } from './directives/byte.pipe';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { PhonePipe } from './pipe/phone';
import { AlertService } from './providers/alert.service';
import { NumberDirective } from './directives/number.directive';
import { Ebook3Component } from './ebook3/ebook3.component';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }
  } as any;

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    PhonePipe,
    MainComponent,
    Ebook1Component,
    Ebook2Component,
    IntroComponent,
    NoticeComponent,
    PartnerComponent,
    DownComponent,
    BytePipe,
    FocusDirective,
    NumberDirective,
    ReversePipe,
    Ebook3Component
  ],
  imports: [
    FormsModule,
    BrowserModule,
    UploadModule,
    HttpClientModule,
    AppRoutingModule
  ],
  providers: [
    PagerService,
    SharedService,
    AlertService,
    HttpProvider,
    { provide: APP_BASE_HREF, useValue : '/' },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
