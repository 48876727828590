import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AppComponent } from "../app.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../providers/shared.service";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-ebook2",
  templateUrl: "./ebook2.component.html",
  styleUrls: ["./ebook2.component.scss"],
  // animations: [
  //   trigger('changeDivSize', [
  //     state('initial', style({
  //       backgroundColor: 'green',
  //       width: '100px',
  //       height: '100px'
  //     })),
  //     state('final', style({
  //       backgroundColor: 'red',
  //       width: '200px',
  //       height: '200px'
  //     })),
  //     transition('initial=>final', animate('1500ms')),
  //     transition('final=>initial', animate('1000ms'))
  //   ]),
  // ]
})
export class Ebook2Component implements OnInit {
  @ViewChild("logics", { static: false }) hanjas: ElementRef;
  logicList: any = [
    { id: 1, src: "logic1.png" },
    { id: 2, src: "logic2.png" },
    { id: 3, src: "logic3.png" },
    { id: 4, src: "logic4.png" },
    { id: 5, src: "logic5.png" },
    { id: 6, src: "logic6.png" },
  ];
  type: number;
  index: number;
  constructor(
    public app: AppComponent,
    public shared: SharedService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.shared.getData().subscribe((data) => {
      if (data.type) {
        this.type = data.type;
        this.index = data.index - 1;
        this.onScroll();
      }
    });
    this.app.changeView(false);
  }

  onScroll() {
    setTimeout(() => {
      let top: number;
      top = this.hanjas.nativeElement.children[this.index].offsetTop - 200;
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    }, 100);
  }

  ebook(id) {
    const src = environment.url + "ebook/logic/e" + id;
    window.open(src);
  }

  song(type, id) {
    this.app.onMedia(type, id);
  }

  onSwipeLeft(event) {
    this.router.navigate(["ebook3"]);
  }
  onSwipeRight(event) {
    this.router.navigate(["ebook1"]);
  }
}
