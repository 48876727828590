import { Injectable } from '@angular/core';
import { Ebook, Partner } from '../_model/ebook';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class SharedService{
  private subject = new Subject<Ebook>();
  private subject2 = new Subject<Partner>();

  sendData(ebook: Ebook) {
    this.subject.next(ebook);
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<Ebook> {
    return this.subject.asObservable();
  }


  sendNotice(partner: Partner) {
    this.subject2.next(partner);
  }

  clearNotice() {
    this.subject2 = new Subject<Partner>();
  }

  getPartner(): Observable<Partner> {
    return this.subject2.asObservable();
  }
}

