import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { AppComponent } from "../app.component";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../providers/shared.service";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-ebook1",
  templateUrl: "./ebook1.component.html",
  styleUrls: ["./ebook1.component.scss"],
})
export class Ebook1Component implements OnInit {
  @ViewChild("hanjas", { static: false }) hanjas: ElementRef;
  @ViewChild("busu", { static: false }) busu: ElementRef;
  type: number;
  index: number;
  hanjaList: any = [
    { id: 1, src: "han1.png", link: "" },
    { id: 2, src: "han2.png", link: "" },
    { id: 3, src: "han3.png", link: "" },
    { id: 4, src: "han3_2.png", link: "" },
    { id: 5, src: "han4.png", link: "" },
    { id: 6, src: "han4_2.png", link: "" },
    { id: 7, src: "han5.png", link: "" },
    { id: 8, src: "han5_2.png", link: "" },
    { id: 9, src: "han6.png", link: "" },
    { id: 10, src: "han6_2.png", link: "" },
  ];
  song1: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];
  song2: any = [
    { id: 1, grade: 1 },
    { id: 2, grade: 2 },
    { id: 3, grade: 3 },
    { id: 4, grade: 4 },
    { id: 5, grade: 5 },
  ];
  constructor(
    public app: AppComponent,
    public shared: SharedService,
    private router: Router,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.shared.getData().subscribe((data) => {
      if (data.type) {
        this.type = data.type;
        this.index = data.index - 1;
        this.onScroll();
      }
    });

    this.app.changeView(false);
  }

  onScroll() {
    setTimeout(() => {
      let top: number;
      if (this.type === 4 || this.type === 5) {
        // 부수관련
        top = this.busu.nativeElement.offsetTop - 350;
      } else {
        top = this.hanjas.nativeElement.children[this.index].offsetTop - 250;
      }
      window.scrollTo({ left: 0, top, behavior: "smooth" });
    }, 100);
  }

  song(type, id) {
    this.app.onMedia(type, id);
  }

  ebook(type, id) {
    const src = environment.url + "ebook/" + type + "/e" + id;
    window.open(src);
  }

  onSwipeLeft(event) {
    this.router.navigate(["ebook2"]);
  }
  onSwipeRight(event) {
    this.router.navigate(["ebook3"]);
  }
}
