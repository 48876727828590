import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AppComponent } from '../app.component';
import { PagerService } from '../providers/pager.provider';
import { UploadService } from '../upload/upload.service';
import { MatDialog } from '@angular/material';
import { HttpProvider } from '../providers/http.provider';
import { forkJoin } from 'rxjs';
declare const download: any;

@Component({
  selector: 'app-down',
  templateUrl: './down.component.html',
  styleUrls: ['./down.component.scss']
})
export class DownComponent implements OnInit {
  @ViewChild('file', {static: false}) file;
  public files: Set<File> = new Set();
  private allItems: any[];
  fileList: any = [];
  originFiles: any = [];
  deleteFileList: any = [];
  secret = false;
  fileLth: any;
  progress: any;
  index: number;
  listId: number;
  pager: any = {};
  pagedItems: any[];
  searchWord = '';
  length = 0;
  popup: any;
  password: string;
  completeCheck = false;
  onPwd = false;
  onDelete = false;
  writeContent: any;
  writeTitle: any;
  secretRow: any = [];
  mode = 0;
  down = [];
  detail = {
    id: 0,
    title: '',
    writer: '',
    content: '',
    datetime: '',
    secret: false,
    count: 0
  };
  modiForm = {
    id: 0,
    title: '',
    content: '',
    files: '',
    secret: false
  };
  search: string = '';
  searchLabel: string = '';

  constructor(
    public app: AppComponent,
    public http: HttpProvider,
    public dialog: MatDialog,
    public uploadService: UploadService,
    public pagerService: PagerService
  ) { }

  ngOnInit() {
    this.searchLabel = '';
    this.app.changeView(false);
    this.loadBoardList();
  }

  loadBoardList() {
    // this.http.get('select_down2').then(res => {
    this.http.get('select_down').then(res => {
      if (res['result']) {
        this.down = res['data'];
        this.allItems = res['data'];
        if (res['data']) {
          this.length = this.allItems.length;
          this.pager = {};
          this.setPage(1);
        }
      }
    });
  }

  loadFileList(id) {
    // this.http.get('select_files2?board_type=1&board_id=' + id).then(res => {
    this.http.get('select_files?board_type=1&board_id=' + id).then(res => {
      if (res['result']) {
        this.fileList = res['data'];
      } else {
        alert('네크워크 오류 발생! 다시 시도해주세요');
      }
    });
  }

  view(list, idx) {
    if (!list.secret) {
      this.index = idx;
      this.loadFileList(list.id);
      this.increase(list.id).then();
      window.scrollTo({left: 0, top: 50, behavior: 'smooth'});
      this.detail = list;
    } else {
      this.mode = 4;
      this.onPwd = true;
      this.secretRow = list;
      this.index = idx;
    }
  }

  increase(id) {
    return new Promise(resolve => {
      // this.http.get('increase_down_2?board_id=' + id).then(res => {
      this.http.get('increase_down?board_id=' + id).then(res => {
        this.allItems[this.index].count++;
      });
    });
  }

  reset() {
    window.scrollTo(0, 0);
    this.detail = {
      id: 0,
      title: '',
      writer: '',
      content: '',
      datetime: '',
      secret: false,
      count: 0
    };
  }

  setPage(page: number) {
    this.reset();
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page, 10);
    this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  // 비밀번호 체크
  checkPwd() {
    switch (this.mode) {
      case 1 : { // 작성
        if (this.password === '5491155') {
          this.completeCheck = true;
          this.onPwd = false;
        } else {
          alert('비밀번호가 일치하지 않습니다');
        }
        this.password = '';
        break;
      }
      case 2 : { // 수정
        if (this.password === '5491155') {
          this.completeCheck = true;
          this.onPwd = false;
          this.originFiles = [];
          for (const i in this.fileList) {
            this.originFiles.push(this.fileList[i]);
          }
          this.modiForm.id = this.detail.id;
          this.modiForm.title = this.detail.title;
          this.modiForm.content = this.detail.content;
          this.modiForm.secret = this.detail.secret;
          this.modiForm.files = '';
          break;
        } else {
          alert('비밀번호가 일치하지 않습니다');
        }
        break;
      }
      case 3 : { // 삭제
        if (this.password === '5491155') {
          this.deleteBoard(this.detail.id);
          this.onPwd = false;
          this.mode = 0;
        } else {
          alert('비밀번호가 일치하지 않습니다');
        }
        break;
      }

      case 4 : { // 비밀글 열람
        if (this.password === '5491155') {
          this.onPwd = false;
          this.loadFileList(this.secretRow.id);
          this.increase(this.secretRow.id).then();
          window.scrollTo({left: 0, top: 50, behavior: 'smooth'});
          this.detail = this.secretRow;
        } else {
          alert('비밀번호가 일치하지 않습니다');
        }
      }
    }
    this.password = '';
  }

  // 글쓰기
  wBoard() {
    this.search = '';
    this.searchLabel = '';
    this.app.onDialog('작성중..');
    const length = this.file.nativeElement.files.length;
    const data = {
      title: this.writeTitle,
      content: this.writeContent,
      secret: this.secret
    };

    // this.http.post('insert_down2', data).then(res => {
    this.http.post('insert_down', data).then(res => {
      if (res['result']) {
        if (length > 0) {
          this.fileUpload(res['id']);
        } else {
          this.wrote();
        }
      } else {
        this.app.offDialog();
        alert('네크워크 오류 발생! 다시 시도하세요');
      }
    });
  }

  // 글쓰고 난 후
  wrote() {
    this.completeCheck = false;
    this.reset();
    this.loadBoardList();
    this.mode = 0;
    this.writeTitle = '';
    this.writeContent = '';
    this.resetFiles();
    this.app.offDialog();
  }

  // 글쓰기 버튼
  writeDown() {
    this.mode = 1;
    this.secret = false;
    this.onPwd = true;
  }

  // 삭제 모듈
  deleteBoard(id) {
    // this.http.get('delete_down2?id='+id).then(res => {
    this.http.get('delete_down?id='+id).then(res => {
      if (res['result']) {
        this.reset();
        this.loadBoardList();
      }
    });
  }


  // About Upload Files
  // ======================================================================
  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    this.fileLth = files.length;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }
  }

  fileUpload(insertId) {
    const names = [];
    this.progress = this.uploadService.upload(this.files);
    const allProgressObservables = [];
    // tslint:disable-next-line:forin
    for (const key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
      names.push(this.progress[key].name);
    }

    forkJoin(allProgressObservables).subscribe(end => {
      this.uploadFileLists(insertId, names);
    });
  }

  uploadFileLists(board_id, names) {
    const list = [];
    const files = this.file.nativeElement.files;
    for (let i = 0; i < files.length; i++) {
      const file = {
        name: files[i].name,
        src: names[i],
        size: files[i].size,
        type: files[i].type
      };
      list.push(file);
    }

    const data = {
      board_id,
      board_type: 1,
      files: list
    };

    this.http.post('insert_files', data).then(res => {
      if (res['result']) {
        this.wrote();
      } else {
        console.log(res['err']);
        this.app.offDialog();
        alert('네크워크 오류 발생! 다시 시도해주세요');
      }
    });
  }

  cancel() {
    this.mode = 0;
    this.completeCheck = false;
    this.resetFiles();
  }

  cancel2() {
    this.mode = 0;
    this.completeCheck = false;
    this.originFiles = [];
    this.deleteFileList = [];
    this.resetFiles();
  }

  resetFiles() {
    this.files = new Set();
    this.file.nativeElement.value = null;
    this.onFilesAdded();
  }

  download(id: number, src: string, name: string) {
    const x = new XMLHttpRequest();
    x.open('GET', 'https://gsedu.co.kr/download/' + src, true);
    x.responseType = 'blob';
    x.onload = (e) => {
      download(x.response, name);
    };
    x.send();
    // this.http.get('increase_down1_2?id=' + id).then(res => {
    this.http.get('increase_down1?id=' + id).then(res => {
      if(res['result']){

      }
    });
  }

  @HostListener('wheel', ['$event']) handleWheelEvent(event) {
    if (this.popup) {
      event.preventDefault();
    }
  }

  deleteFile(idx) {
    this.deleteFileList.push(this.originFiles[idx]);
    this.originFiles.splice(idx, 1);
  }

  returnFile(idx){
    this.originFiles.push(this.deleteFileList[idx]);
    this.deleteFileList.splice(idx, 1);
  }

  modify() {
    this.app.onDialog('작성중..');
    const deleteIds = [];
    if (this.deleteFileList.length > 0) {
      for (const i in this.deleteFileList) {
        deleteIds.push(this.deleteFileList[i].id);
      }
      const test = deleteIds.join();
      this.modiForm.files = test;
    }
    const length = this.file.nativeElement.files.length;

    // this.http.post('update_down2', this.modiForm).then(res => {
    this.http.post('update_down', this.modiForm).then(res => {
      if (res['result']) {
        if (length > 0) {
          this.fileUpload(this.modiForm.id);
        } else {
          this.wrote();
        }
      } else {
        this.app.offDialog();
        alert('네크워크 오류 발생! 다시 시도하세요');
      }

      this.deleteFileList = [];
    });
  }


  searching() {
    if (this.search === '' || !this.search) {
      alert('검색어를 입력하세요');
    } else {
      this.searchLabel = this.search;
      this.app.onDialog('검색중..');
      this.http.post('search_down', {keyword: this.search}).then(res => {
        if (res['result']) {
          this.down = res['data'];
          this.allItems = res['data'];
          if (res['data']) {
            this.length = this.allItems.length;
            this.pager = {};
            this.mode = 0;
            this.setPage(1);
            this.app.offDialog();
          }
        }
      });
    }
  }
}



